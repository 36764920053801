<template>
  <div class="not-found">
    <a-result
      status="404"
      title="404"
      sub-title="您所寻找的资源不存在，请确认访问路径！"
    >
    </a-result>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.not-found{
  height: 100%;
}
</style>